<template>
  <div>
	<CRow id="dashboard">
		<CCol sm="12" md="6" lg="2" v-for="(item, index) in items" :key="index">
			<CCard v-c-tooltip.hover="{content: `${item.updated_at}`, placement: 'bottom'}">
				<CCardBody >
					<router-link :to="'/data/proccesses/' + item.id + '/details'">
						<div class="text-muted font-weight-bold">{{item.name}}</div>
					</router-link>

					
					<div class="text-value-lg" :id="`id-${item.id}`" style="font-size:20px"
						@click="color_picker=true;id=`id-${item.id}`">{{item.last_data}}</div>

					<div class="text-muted font-weight-bold" @click="makeRequest(item.id)" v-show="$store.state.perm['arbitrage.DataSources'].post && item.is_gpt_task"><b>( ChatGPT - Click To Update )</b></div>

				</CCardBody>
			</CCard>
		</CCol>
	</CRow>


	<CModal
		title="Styles"
		size="sm"
		color="primary"
		:show.sync="color_picker"
		>
		<color-picker v-model="color" />
		<br>
		<CSelect
			v-model="font_family"
			:options="[
				{value: 'Oswald', label:'Oswald'},
				{value: 'Teko', label:'Teko'},
				{value: 'Yanone Kaffeesatz', label:'Yanone Kaffeesatz'},
				{value: 'Cairo', label:'Cairo'},
				{value: 'Rajdhani', label:'Rajdhani'},
				{value: 'Julius Sans One', label:'Julius Sans One'},
				{value: 'Syncopate', label:'Syncopate'},
				{value: 'Reem Kufi', label:'Reem Kufi'},
				{value: 'Lekton', label:'Lekton'},
			]"
			placeholder="Please Select Font Family"
			@change="font_family=$event.target.value"
		/>
		<CSelect
			v-model="font_size"
			:options="[
				{value: '14', label:'14'},
				{value: '15', label:'15'},
				{value: '16', label:'16'},
				{value: '18', label:'18'},
				{value: '20', label:'20'},
				{value: '22', label:'22'},
				{value: '24', label:'24'},
				{value: '26', label:'26'},
				{value: '28', label:'28'},
				{value: '32', label:'32'},
			]"
			placeholder="Please Select Font Size"
			@change="font_size=$event.target.value"
		/>
		<CInput v-model="position" placeholder="Positoin Number" maxlength="3"
			onkeypress="return event.charCode > 48" />
		<template #footer>
			<CButton @click="set_default" color="secondary">Default</CButton>
			<CButton @click="set_styles" color="primary">Submit</CButton>
		</template>
	</CModal>
  </div>
</template>




<script>
import { Sketch } from 'vue-color'
export default {
	components: {'color-picker': Sketch},
	data(){
		return{
			items: [],
			interval: [],
			last_update: '',
			color_picker: false,
			color: '',
			font_family: '',
			font_size: '',
			id: '',
			is_exist: null,
			styles: null,
			position: null,
		}
	},
	created(){
		this.styles = JSON.parse(localStorage.getItem('styles'));
		this.get();
		this.interval = setInterval(() => {this.get()}, 1000);
	},
	methods: {
		makeRequest(itemId) {
      		
			this.axios.get('arbitrage/input_proccesses/update-from-gpt/?id='+itemId)

			this.$notify({
				group: 'foo',
				type: 'success',
				text: 'Task start in background !',
			});

    	},
		set_styles(){
			this.is_exist = false;
			if(this.color != ''){
				this.color = this.color.hex;
				document.getElementById(this.id).style.color = this.color;
				this.styles.map(val => {
					if(val.id == this.id){
						val.color = this.color;
						this.is_exist = true;
					}
				});
			}
			if(this.font_family != ''){
				this.font_family = `${this.font_family}, sans-serif`;
				document.getElementById(this.id).style.fontFamily = this.font_family;
				this.styles.map(val => {
					if(val.id == this.id){
						val.font_family = this.font_family;
						this.is_exist = true;
					}
				});
			}
			if(this.font_size != ''){
				this.font_size = `${this.font_size}px`;
				document.getElementById(this.id).style.fontSize = this.font_size;
				this.styles.map(val => {
					if(val.id == this.id){
						val.font_size = this.font_size;
						this.is_exist = true;
					}
				});
			}
			if(this.position != null){
				this.styles.map(val => {
					if(val.id == this.id){
						val.position = this.position;
						this.is_exist = true;
					}
				});
			}
			if(this.is_exist == false){
				this.styles.push({id: this.id, color: this.color,
					font_family: this.font_family,
					font_size: this.font_size,
					position: this.position});
			}
			localStorage.setItem('styles', JSON.stringify(this.styles));
			this.color = '', this.id = '', this.font_family = '', this.font_size = '', this.position = null;
			this.color_picker = false;
		},
		set_default(){
			document.getElementById(this.id).style.color = '';
			document.getElementById(this.id).style.fontFamily = '';
			document.getElementById(this.id).style.fontSize = '';
			this.styles.map(val => {
				if(val.id == this.id){
					val.color = '', val.font_family = '', val.font_size = '', val.position = null;
				}
			});
			this.get();
			localStorage.setItem('styles', JSON.stringify(this.styles));
			this.color_picker = false;
		},
		get() {
			let query = '';
			for(const i in this.styles){
				if(this.styles[i].position != null){
					const id = this.styles[i].id.split('-');
					query += `${id[1]}_${this.styles[i].position},`;
				}
			}
			this.axios.get('arbitrage/input_proccesses/'
				+ '?random_id=' + Math.random()
				+ '&order_by=' + this.$route.params.order_by
				+ '&order_type=' + this.$route.params.order_type
				+ '&custom_order_by=' + query)
				.then(res => {
					this.styles.map(val => {
						try{
							document.getElementById(val.id).style.color = '';
							document.getElementById(val.id).style.fontFamily = '';
							document.getElementById(val.id).style.fontSize = '';
						}
						catch(err){}
					});
					res.data.map(val => {
						if (val.is_gpt_task == false){
							val.last_data = parseFloat(val.last_data).toFixed(val.data_precision);
							val.last_data = String(val.last_data).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}

						if (val.last_activity != '0001-01-01T00:00:00Z'){
							val.updated_at = this.$moment(val.last_activity, 'YYYY-MM-DD HH:mm:ss').locale('fa').format('jYYYY-jMM-jDD HH:mm:ss');
						}
						
					});
					this.items = res.data;
				})
				.catch(err => {
					if (err.message == 'Network Error') {
						this.$notify({
							group: 'foo',
							type: 'error',
							text: 'No Internet Connection!',
						});
					}
				})
				.finally(() => {
					this.styles.map(val => {
						try{
							document.getElementById(val.id).style.color = val.color;
							document.getElementById(val.id).style.fontFamily = val.font_family;
							document.getElementById(val.id).style.fontSize = val.font_size;
						}
						catch(err){}
					});
				})    
        },
	},
	beforeDestroy(){
		clearInterval(this.interval);
	}
}
</script>